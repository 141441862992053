import React, { useState } from "react";
import SectionAbout from "../../components/Home/SectionAbout";
import MeetTheTeam from "../../components/Home/MeetTheTeam";

const About = () => {
  return (
    <div>
      {/* SectionAbout */}
      <SectionAbout />
      {/* MeetTheTeam */}
      <MeetTheTeam />
    </div>
  );
};

export default About;
