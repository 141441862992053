import React from "react";
import "./styles.css";

const Section1 = () => (
  <header className="home-header">
    <h2>Preparations and Events</h2>

    <p>

      Behind every event lies the heart and effort of our community. 
      The excitement of planning, the shared anticipation, and the joy of seeing it all come together have been as memorable as the events themselves. <br />{" "}
      Whether organizing trips, coordinating meetups, or simply lending a helping hand, the spirit of collaboration within our group shines brightly.<br />{" "}

    </p>
    <h1>
      <span>What's new</span> LADIES <span>?</span>
    </h1>
  </header>
);

export default Section1;
