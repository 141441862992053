import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="Box">
      <div className="Container">
        <div className="Row">
          <div className="Column">
            <div className="Heading">Created by</div>
            <a href="https://kedi-design.com/" className="FooterLink">
              <span>Kedi Design</span>
            </a>
          </div>
          <div className="Column">
            <div className="Heading">Org Nr</div>
            <div className="FooterLink" href="#">
              930 872 865
            </div>
          </div>
          <div className="Column">
            <div className="Heading">Contact Us</div>

            <div className="FooterLink">
              <a href="mailto:ladiesofstavanger@gmail.com">e-mail</a>
            </div>
          </div>
          <div className="Column">
            <div className="Heading">Social Media</div>{" "}
            <div>
              <a
                href="https://www.facebook.com/groups/ladiesofstavanger"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="SocialIconMargin FooterLink"
                  icon={faFacebookF}
                  size="xl"
                />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="SocialIconMargin FooterLink"
                  size="xl"
                  icon={faInstagram}
                />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="FooterLink"
                  size="xl"
                  icon={faLinkedin}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
