export const teamData = [
  {
    name: "Izabela Buczynska",
    job: "CEO/Executive Director",
    img: "https://profitbasedocs.blob.core.windows.net/other/Iza.jpg",
  },
  {
    name: "Aashima Budal",
    job: "Development Director",
    img: "https://profitbasedocs.blob.core.windows.net/other/Aashima.jpg",
  },
  {
    name: "Roma Nice-Vaisvile",
    job: "Operations Director",
    img: "https://profitbasedocs.blob.core.windows.net/other/Roma.jpg",
  },
  {
    name: "Lena Amdal",
    job: "Events Director",
    img: "https://profitbasedocs.blob.core.windows.net/other/Lena.jpg",
  },
];
