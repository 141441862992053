export const blogList = [
  { 
    id: 1,
    title: "THAT TIME Comic Book Reading",
    category: "Celebrations",
    subCategory: ["party", "let`s meet", "educational"],
    description: `🌺🍹 Aloha Wednesdays: This time with a twist 🍹🌺

    
📚✨ My dear Ladies of Stavanger,  💖
this time our Wednesday meeting begun differently. We had a theme!  ✨📚

Wednesday’s meetup at Bardello was a hit! We kicked off with a comic book on the joys and trials of pregnancy, adding a fun twist to our usual mingle. 💖🎉

It was a night full of laughter, heartfelt moments, and support for one another. Thanks to everyone who made it so special! 🌟


xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "5 September, 2024",
    cover: "/assets/images/marina.jpeg",
  },
  { 
    id: 2,
    title: "Stavanger Marathon!",
    category: "Sport",
    subCategory: ["stay active", "let`s run together", "motivation"],
    description: ` 🏃‍♀️🌟 Ladies of Stavanger Conquer the 5K! 🌟🏃‍♀️

We laced up and took on the Stavanger Marathon 5K, and what an experience it was! After weeks of rain, we were greeted with a beautiful, sunny day—perfect for our run! ☀️🏅 The heat added a fun challenge, and crossing the finish line to receive that gorgeous medal made it all worthwhile.

Next up: The Pink Ribbon Run! Whether it’s 3K or 6K, let’s continue running for a cause and showing our support. 💖🎗️


xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "1 September, 2024",
    cover: "/assets/images/maraton.jpeg",
  },
  { 
    id: 3,
    title: "Monthly Meetups at Aloha Stavanger",
    category: "Celebrations",
    subCategory: ["party", "let`s meet", "recurring event"],
    description: `🌺🍹 Aloha Wednesdays: Our Monthly Tradition 🍹🌺

    
Every First Wednesday of the month! 📅✨

Our monthly meetups at Aloha Stavanger have become the heartbeat of our group — a tradition that pulses with warmth, laughter, and the joy of connection. 💖😊 Each first Wednesday offers us a sanctuary from the everyday, where new friendships blossom 🌸 and old ones deepen. 🤝💫

These evenings, filled with lively conversations and shared stories, 🗣️✨ have been a beautiful reminder of the power of community. 💕👥

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "23 August, 2024",
    cover: "/assets/images/ladies.jpg",
  },
  { 
    id: 4,
    title: "Join Padel group! We play weekly!",
    category: "Sport",
    subCategory: ["stay active", "let`s play together", "recurring event"],
    description: ` Come and join us, every level is welcome! 🎉

    
In February, we embraced the spirit of fun and fitness with a lively game of padel.🎾

This energetic sport is a delightful way to shake off the winter blues and start the year with a burst of activity.❄️➡️💪

The friendly competition brought out the best in us, leaving us invigorated and more connected than ever, so we decided to play regularly. 🤝🎾

Join us @ Whatsapp! 📲
https://chat.whatsapp.com/Frx9h14JsUzIHGZZBdixnr

xoxo, Roma 💕


`,
    authorName: "Roma Nice-Vaisvile",
    authorAvatar: "/assets/images/author5.jpg",
    createdAt: "23 August, 2024",
    cover: "/assets/images/Padel1.jpeg",
  },
  { 
    id: 5,
    title: "Join Water Polo women's team!🥈",
    category: "Sport",
    subCategory: ["stay active", "let`s play together", "recurring event"],
    description: `  Great atmosphere and an interesting  challenge!

    
Exciting news! The Stavanger Water Polo Club now has its very own women's team, and we're looking for new members! 🌊🤽‍♀️

If you have a passion for water, love swimming, and enjoy the thrill of a contact sport, this could be the perfect fit for you.
Whether you're experienced or new to the game, we'd love to welcome you to our team! 

Come join us for a great time in the pool and be part of something amazing. 💦
xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "23 August, 2024",
    cover: "/assets/images/wpolo2.jpg",
  },
  { 
    id: 6,
    title: "We Love 90's & 2000's concert!",
    category: "Concerts",
    subCategory: ["90's", "let's jump together", "party"],
    description: ` Ladies of Stavanger Enjoyed some 🎵🚀 Concerts!

    What a blast we had at the We Love the 90s & 2000s concert! 🌴 From Vengaboys to Cascada, Hanson, and Big Brovaz, the energy was electric, and the nostalgia was real! 
    The music took us back to the best times, and the whole event was filled with dancing, singing, and all-around fun. 💃🎤

    At the end of the night, we even got to greet some of the artists and showed them the best of Stavanger's nightlife! 💃 
    What a perfect way to top off an already incredible evening!

    Special shoutout to the two lucky Ladies who won tickets through our giveaway! 💪🌟
🎟️🥳 The performances were epic, and it was even better sharing the moment with our amazing community. 🎉✨


See you at the next one!

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "10 August, 2024",
    cover: "/assets/images/WeLove90.jpg",
  },
  { 
    id: 7,
    title: "The Blink ‘24 concert",
    category: "Concerts",
    subCategory: ["90's", "let's jump together", "party"],
    description: `  What a BLAST it was Ladies of Stavanger!🎉✨

    
The Blink ‘24 concert in August was a testament to the adventurous spirit that runs through our group. 🎵🚀

Though only one of us braved the evening when plans changed, it was a night of music and memories that exemplified the courage to embrace new experiences—even when they don’t go as expected.💪🌟
xoxo, Roma 💕 


`,
    authorName: "Roma Nice-Vaisvile",
    authorAvatar: "/assets/images/author5.jpg",
    createdAt: "3 August, 2024",
    cover: "/assets/images/Blink24.jpeg",
  },
  { 
    id: 8,
    title: "Hytte trip to Sirdal",
    category: "Travel",
    subCategory: ["Hikes", "Let's travel", "Hytte"],
    description: `🏞️🍃 Sirdal Escape: Nature, Nurture, and Good Times 🍃🏞️

Our Sirdal getaway was a perfect weekend retreat in a cozy cottage house in Sirdal. 🏡✨ 
Surrounded by nature, we spent our days hiking through beautiful landscapes 🌲👣 and pampering ourselves with some well-deserved relaxation. 💆‍♀️🧖‍♂️ 

Evenings were filled with cooking delicious meals together 🍳🥘 and simply enjoying the peace of the outdoors(besides the mosquitoes, maybe 😁🦟). 
It was a weekend of nature, nurture, and pure enjoyment. 🌿💚 

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "1 August, 2024",
    cover: "/assets/images/Sirdal.jpeg",
  },
  { 
    id: 9,
    title: "Girls’ Night Out in Sandnes",
    category: "Celebrations",
    subCategory: ["party", "Let's meet", "Sandnes"],
    description: ` Rogaland is ours, Ladies of Stavanger!💪🌟

    
July brought us a Girls’ Night Out in Sandnes, a smaller gathering that proved once again that it’s the quality of connections that matters most. 

With just one new face joining us, the evening was intimate yet impactful, leaving us with a sense of sisterhood and shared understanding that only deepens with time. 🥂✨

xoxo, Roma 💕


`,
    authorName: "Roma Nice-Vaisvile",
    authorAvatar: "/assets/images/author5.jpg",
    createdAt: "20 July, 2024",
    cover: "/assets/images/July24.jpeg",
  },
  { 
    id: 10,
    title: "Ladies of Stavanger Run in Sola!",
    category: "sport",
    subCategory: ["motivation", "stay active", "let`s run together"],
    description: `Solnedgangen 5 km run: Running into the Sunset 🌅🏃‍♂️.


    We took part in the Solnedgangen 5K run in Sola, and it was an unforgettable experience! 🏃‍♀️🏃‍♂️ 
    Our main goal was to motivate each other, training together once a week before the big day. 💪✨ 
    
    The run took place along the beach, making it all the more enjoyable with the stunning coastal views 🌊. 
    The weather was perfect, and with a 21:30 start time, we got to watch a breathtaking sunset as we ran. 🌅 
    
    It was more than just a race—it was a beautiful, energizing way to come together as a team. 🌟

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "19 June, 2024",
    cover: "/assets/images/solaRun.jpeg",
  },
  { 
    id: 11,
    title: "Summer Party",
    category: "Celebrations",
    subCategory: ["party", "Let's meet", "Summer Party"],
    description: `🎉✨ Let's FLAMINGLE party ✨🎉


June’s Summer Party was a night of pure magic! 💫
The air was filled with the sounds of laughter and music 🎶, the tables adorned with vibrant decorations 🎈, and the dance floor alive with energy 💃🕺.

It was an evening that embodied the joy and celebration that defines our group—a night we will all treasure in our hearts. 🥂💖✨

Shout out to Roma 🙌 for putting so much effort and time into organizing this colorful event! 🌈🎨

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "8 June, 2024",
    cover: "/assets/images/LoSSummerParty.jpeg",
  },
  { 
    id: 12,
    title: "7 Tops Tour in Sandnes",
    category: "Hiking",
    subCategory: ["sport", "stay active", "mountains"],
    description: `🏞️ Conquering Heights: The 7 Tops Tour Adventure 🏞️

    
Our adventurous spirit took centre stage in late May with the 7 Tops Tour in Sandnes. ⛰️ 

This challenging hike tested our endurance 💪, but the camaraderie and encouragement we shared made every step worthwhile. 👣💫 

As we stood atop those peaks, surrounded by stunning landscapes 🌄, we knew we had accomplished something truly special together. 🙌🥾

xoxo, Roma 💕


`,
    authorName: "Roma Nice-Vaisvile",
    authorAvatar: "/assets/images/author5.jpg",
    createdAt: "26 May, 2024",
    cover: "/assets/images/7Nuts.jpeg",
  },
  { 
    id: 13,
    title: "Billiard & Dining",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: `  🎱🍽️ "Billiards & Bites - Chill Evening 🍽️🎱!

    
On the 9th of May, we embraced a more relaxed vibe with an evening of billiards and dining. 🎱✨ 

This gathering was the perfect blend of casual fun and culinary delight 🍔🍷, giving us the chance to unwind and enjoy each other’s company in a laid-back setting.😌🍽️ 

 It was a night filled with easy laughter, delicious food, and the simple pleasure of being together. 🥂😁

xoxo, Aashima 💕


`,
    authorName: "Aashima Budal",
    authorAvatar: "/assets/images/author4.jpg",
    createdAt: "9 May, 2024",
    cover: "/assets/images/Billiard.jpeg",
  },
  { 
    id: 14,
    title: "Trip to Månafossen",
    category: "Hiking",
    subCategory: ["sport", "stay active", "mountains"],
    description: `  🌿💧 Chasing Waterfalls 💧🌿

    
April took us deep into nature with an unforgettable hike to the majestic Månafossen waterfall. 🌲💦 
The path was challenging, but the rewards were absolutely breathtaking. 😍 
As we stood together at the foot of the cascading falls, we felt a shared sense of achievement and awe. 
🌊💪 It was a day where nature’s beauty mirrored the strength and resilience within us all. 🌟🗻

xoxo, Roma 💕


`,
    authorName: "Roma Nice-Vaisvile",
    authorAvatar: "/assets/images/author5.jpg",
    createdAt: "20 April, 2024",
    cover: "/assets/images/Manafossen.jpeg",
  },
  { 
    id: 15,
    title: "Trip to Gdańsk",
    category: "Travel",
    subCategory: ["abroad", "Let's travel", "Opera"],
    description: `✈️ Gdańsk Getaway: A Journey to Repeat ✈️


January ushered in a truly memorable experience with our trip to Gdańsk. 🏰✨ 
The cobblestone streets, rich history, and delicious Polish cuisine 🥟🍲 provided the perfect backdrop for an adventure that was as enriching as it was enjoyable. 🌍 

The highlight of our trip was attending the opera Madame Butterfly 🎭🎶, a breathtaking performance that left us in awe. 

More than just a journey, it was a celebration of the bonds we share — a time to explore, laugh, and create memories that will stay with us forever. 👫💖📸

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "30 January, 2024",
    cover: "/assets/images/gdn2.jpeg",
  },
  { 
    id: 16,
    title: "Happy New Year!",
    category: "Celebrations",
    subCategory: ["party", "stay positive", "2024"],
    description: `  A Sparkling Start to the New Year for the Ladies of Stavanger!

    
Cheers to the ladies of Stavanger, may your New Year sparkle with the brilliance of the Northern Lights! May your days be filled with laughter echoing through cobblestone streets and your nights adorned with the beauty of shared moments under the dancing auroras. Here's to a year of prosperity, adventure, and unwavering joy for the radiant women of Stavanger! 🥂✨

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "1 January, 2024",
    cover: "/assets/images/sylwek.jpg",
  },
  { 
    id: 17,
    title: "Unwrapping Joy!",
    category: "parties",
    subCategory: ["Christmas", "comunity", "let`s meet"],
    description: `  Jingle, Mingle, and Jolly: Christmas Party Extravaganza!

    Julebord was an absolute blast! 🎉 A huge shoutout to Ljerka Kukurin for capturing the magical moments, check out her fantastic pictures on https://tinyproductions.no/ 📸. Thanks to SelfieBox (https://selfieboks.no/) for adding an extra flair to our party!

The evening was filled with joy, laughter, and amazing women coming together for good food and great company. 🌟 Every girl left with a Christmas gift from our gift lottery, and one lucky winner scored a special treat – a hot stone massage by Ana Rita Fernandes from 7-Sense (https://booksalon.no/7-sense). 🎁💆‍♀️

We even had a hilarious moment with Santa, lots of laughter, delicious food, and to cap it off, we danced and sang our hearts out in karaoke. What a fantastic night! 🎤💃 

xoxo Your Santa!


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "18 December, 2023",
    cover: "/assets/images/julebord.jpg",
  },
  { 
    id: 18,
    title: "A Jazzy Afternoon at The Garden Stavanger!",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: `  Jazz Bliss: The Garden Stavanger's Spectacular Afternoon Soiree!

    The post-party recap is in, and it was nothing short of fantastic! The jazz afternoon at The Garden Stavanger on November 5 was a hit, with attendees enjoying delightful jazz tunes, a scrumptious late lunch from the à la carte menu, and an all-around great time. The ambiance, delicious food, and soulful jazz music combined for a memorable gathering that left everyone with smiles and satisfied palates. Cheers to good company, great music, and delicious bites at The Garden Stavanger's jazz-filled afternoon! 🎷🎶🍽️

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "7 November, 2023",
    cover: "/assets/images/jazz.png",
  },
  { 
    id: 19,
    title: "Ladies of Stavanger Run for a Good Cause Again!",
    category: "sport",
    subCategory: ["charity", "stay active", "let`s run together"],
    description: `  Ladies of Stavanger Shine in Their Second Pink Ribbon Run.

    For the second time, Ladies of Stavanger are participating in the Pink Ribbon Race, a charity run that's all about making a difference.

Last year, we shared our story as we ran to stay fit and support a great cause. This time around, our dedication is even more noteworthy.

The goal is simple: come together to fight breast cancer.

The event has two run options: a short 3 km route and a longer 6 km route, with categories for both men and women. It's inclusive and welcoming.

Before the race, everyone warms up together, showing solidarity and unity. Ladies of Stavanger and other participants are excited to support those affected by breast cancer.

This year's Pink Ribbon Race is even more special because more ladies from Stavanger have joined in. Their commitment to raising awareness and funds for breast cancer is inspiring.

In the month leading up to the Pink Ribbon Race, the Ladies of Stavanger showed their dedication by meeting every Tuesday for group runs at Mosvannet. These practice sessions were not only about physical preparation but also an opportunity for building camaraderie and motivating each other. Through their shared commitment and regular training, they're more than ready to take on the challenge of the Pink Ribbon Race.

Join the Pink Ribbon Race and stay active while making a real impact. Let's stand together and make a positive change in our community! See you next year Ladies!

xoxo, Izabela 💕


`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "7 October, 2023",
    cover: "/assets/images/RSrun.jpg",
  },
  {
    id: 20,
    title: "50 Cent's Concert Girl!",
    category: "Concerts",
    subCategory: ["Rap", "let's jump together", "party"],
    description: ` Ladies of Stavanger Enjoyed an Electrifying Night at 50 Cent's Concert!

    In an electrifying night out, the Ladies of Stavanger gathered to attend 50 Cent's concert, and it was nothing short of amazing! The dazzling twerk performance by the dancers on stage left everyone in awe, making the entire show truly impressive. 🎤💃🕺
    
    The concert was a fantastic opportunity for us to come together, enjoy great music, and create lasting memories. It was a night we won't soon forget! 🎶🎉
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "3 October, 2023",
    cover: "/assets/images/50cent.jpg",
  },
  {
    id: 21,
    title: "Art Therapy - first chilling together",
    category: "psychology",
    subCategory: ["art", "positive vibes", "let`s create together"],
    description: ` A Magical Evening at Gausel Bydelshus: Crafting Dreams and Connection.
    
    Our recent art therapy event at Gausel Bydelshus was a resounding success. Participants, all of whom were wonderful ladies, enjoyed an evening of self-discovery, creativity, and personal growth, culminating in the creation of vibrant vision boards that captured their dreams and aspirations.

The evening commenced with a meaningful visualization exercise, helping each attendee tap into their emotions, needs, and goals. It set the stage for the artistic journey that followed.

With newfound clarity, participants embarked on crafting their vision boards, equipped with scissors, glue, magazines, and personal mementos. Laughter and shared stories filled the room, creating a heartwarming atmosphere.

Throughout the event, we took breaks to enjoy delicious cookies and sip on a variety of tasty teas. These moments offered relaxation, mingling, and the joy of creation.

The culmination was a collection of unique and colorful vision boards, each a masterpiece, portraying the dreams, goals, and desires of each participant.

As the event wrapped up, every attendee left with their completed vision board—an amazing piece of art serving as a constant reminder of their goals and dreams.

We extend our gratitude to all the ladies who joined us at Gausel Bydelshus. Your presence and enthusiasm made this event extraordinary. Stay tuned for future creative adventures and personal discoveries, as we plan more of art therapy events.

xoxo - Iza
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "30 September, 2023",
    cover: "/assets/images/aTherapy.jpg",
  },

  {
    id: 22,
    title: "Twerk it off!",
    category: "sports",
    subCategory: ["twerk", "dancing", "workshop"],
    description: ` We recently had a blast at the 'Funk: The Brazilian Twerk Workshop,' and we can't wait to share the excitement with you. Our Ladies of Stavanger had an amazing time shaking it off, and now we have an exclusive -50 NOK discount for 'Pop Divas'—another incredible workshop with Myra Hirano-Zoladkowska.

    Join us for 'Pop Divas,' use our discount code, and get ready to dance, laugh, and connect with an awesome community. After the last workshop, we enjoyed delicious food, cakes, coffee, and fantastic conversations. Don't miss out on this opportunity to have a fabulous time!
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "24 September, 2023",
    cover: "/assets/images/twerk.png",
  },
  {
    id: 23,
    title: "Paddle tennis",
    category: "sports",
    subCategory: ["Paddle tenis", "Stay active", "let`s play together"],
    description: ` Are you tired of your usual exercise routine? Bored with the same old sports? Well, look no further, because we've got just the thing to put a smile on your face and a spring in your step: Padel Tennis! Wait, what's Padel Tennis, you ask? Let us enlighten you in a jiffy!

Padel Tennis is a delightful blend of tennis and squash, sprinkled with a generous dose of fun and excitement. Imagine a sport that combines the strategic gameplay of tennis, the speedy footwork of squash, and the pure joy of smacking a ball against a wall. That's Padel Tennis in a nutshell! Picture yourself on a sunny afternoon, surrounded by good company, swinging a paddle with finesse, and creating memorable moments on the court.

Now, if you find yourself longing for that perfect paddle partner, fear not! We are here to make your Padel dreams come true. Our friendly group endeavors to organize Padel training sessions every Friday afternoon, right in the heart of Sandnes. Whether you prefer the great outdoors or the cozy indoors, we've got you covered. However, here's a little secret we'll let you in on: if we gather enough interest, we might just create a brand new Padel group in Stavanger too!

One of the best things about our Padel gang is that we're all in the same boat—we're not advanced players, and we're all learning together. There's absolutely no pressure, and judgment is strictly off-limits. We come together to have a blast, play our hearts out, and maybe, just maybe, do a little happy dance or two when a shot goes exactly where we want it to.

So, if you're ready to embark on a Padel adventure filled with laughter, camaraderie, and a healthy dose of exercise, grab your paddle, lace up your sneakers, and join us on the court. Trust us, you won't regret it. Our motto is simple: fun first, skills second! Together, we'll paddle our way to epic memories and build a community that cherishes the joy of the game.

Remember, whether you're a seasoned player or a complete beginner, our Padel Tennis group welcomes everyone with open arms (and paddles). So, don't be shy! Come along, give it a try, and prepare yourself for a world of fun, excitement, and a whole lot of jumping around. We can't wait to meet you and create some smashing memories together!

For more information and to join our fabulous Padel crew, contact us at ladiesofstavanger@gmail.com. See you on the court, fellow paddle enthusiasts!
    
    `,

    authorName: "Lena Maria Amdal",
    authorAvatar: "/assets/images/author3.jpg",
    createdAt: "6 July, 2023",
    cover: "/assets/images/paddle.jpg",
  },
  {
    id: 24,
    title: " Summer Party ",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: ` Celebrating Summer Bliss at  ØST's Spectacular June Party!
    
    The enchanting season of summer has finally arrived, bringing with it warmth, sunshine, and a sense of joy. On the 24th of June,  ØST transformed into a haven of happiness as a group of 40 fabulous girls gathered for an unforgettable summer party. The weather was simply perfect, the company was delightful, and the delectable treats from Fortau added an extra touch of culinary delight. Let's dive into the highlights of this fantastic event that brought together friends, laughter, and the vibrant spirit of summer.

Fun Under the Sun:
As the sun graced us with its radiant presence, the picturesque setting of Stavanger Øst served as the perfect backdrop for our June Summer Party. The air was filled with anticipation as girls from all walks of life donned their summer dresses, shorts, and sandals, ready to embrace the joyous occasion. Laughter and excitement resonated through the atmosphere.
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "25 June, 2023",
    cover: "/assets/images/summer.jpg",
  },
  {
    id: 25,
    title: "Celebrating 17th of May in Norway!",
    category: "traditions",
    subCategory: ["17.Mai!", "constitution day", "parade"],
    description: ` Gratulerer med dagen Norge!
    
    Let's give a hip hip hoorah for 17.Mai! Today, we join hands to celebrate Norway's Constitution Day, a day filled with joy, pride, and a strong sense of national unity. Whether you're with your family or friends, we hope you have an incredible time partaking in the festivities that adorn the towns and cities throughout the country.
    
    The 17th of May, marks the signing of the Norwegian Constitution in 1814, a crucial step towards the nation's independence and sovereignty. This special occasion has been cherished by Norwegians for centuries, and the spirit of celebration only grows stronger with each passing year.

    The air is infused with excitement as people gather in the streets, adorned in their finest traditional costumes or vibrant national colors. The joyous atmosphere is contagious, and the sound of cheerful chatter and laughter fills the air. The day kicks off with vibrant parades featuring marching bands, schoolchildren waving flags, and community organizations proudly displaying their banners.

    Throughout the day, you'll witness the community coming together to celebrate. From neighborhood gatherings and barbecues to picnics in the park, the spirit of camaraderie and togetherness is palpable. It's a day to cherish time spent with loved ones, creating beautiful memories that will be treasured for years to come.

    So, ladies, let's embrace the enchantment of this day and revel in the joyous celebrations that surround us. Let's wave our flags high, cheer with all our might, and let the spirit of 17th of May fill our hearts. Today, we celebrate Norway, its remarkable history, and the indomitable spirit of its people.
    
    Gratulerer med dagen, Norge! Have a fantastic day!
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "17 May, 2023",
    cover: "/assets/images/17th.jpg",
  },

  {
    id: 26,
    title: "BBQ Spring Party Matsaya Bobas & BBQ! ",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: ` Spring has arrived and what better way to celebrate than with a BBQ!

    Spring has arrived and what better way to celebrate than with a BBQ and Hotpot party at Matsaya Bobas and BBQ located in the heart of Nytorget Stavanger Sentrum? On April 1st, twenty-four group members came together in their colorful spring outfits to savor the flavors of Asian cuisine and welcome the new season. It was wonderful to celebrate with our regular members and welcome new ones to our group events.

We enjoyed an array of mouth-watering dishes, including savory and spicy Korean BBQ and sumptuous hotpot, where we got to choose our preferred meats, veggies, sauces, and soups. As we cooked together and chatted, we sipped on refreshing boba tea and savored some delicious Filipino beer. It was a memorable experience to share the culinary delights of Asia with my fellow group members, especially since we all come from diverse backgrounds.

We started the day with our usual round of introductions, which allowed us to get to know each other and welcome our new members into our group. Despite being strangers, the vibe was warm and cozy, as if we had known each other for ages. That's the magic of our community - we all share the same goal of meeting new people and fostering a sense of sisterhood and community in a foreign town.

But the celebration didn't end there. We continued our festivities at the Matsaya Ramen lounge, where we raised a toast to my birthday, sang and danced to the beats of Latin, Western, and European music, and enjoyed bottles of pink cava that I shared with the group. It was a celebration not just of my birthday, but also of the new season and the many exciting plans we have in store for The Ladies of Stavanger. Stay tuned for more updates!

In conclusion, it was a fantastic day filled with delicious food, refreshing drinks, and lots of laughter. We can't wait to do it again and create more memories with our amazing group. Keep an eye on this space for more exciting news to come!

xoxo 
Sheryll 

    
    `,

    authorName: "Sheryll Johannesen",
    authorAvatar: "/assets/images/author2.jpg",
    createdAt: "2 April, 2023",
    cover: "/assets/images/springParty.jpg",
  },

  {
    id: 27,
    title: "Water Polo in Stavanger: Join the Fun!",
    category: "sport",
    subCategory: ["water polo", "stay active", "let`s swim together"],
    description: ` Water Polo in Stavanger: Join the Fun!
    
    Are you looking for a new, exciting sport to try out in Stavanger? Have you always been a water creature, but never quite found the right activity to satisfy your aquatic cravings? Look no further than Water Polo Stavanger!

    Water Polo is a team sport that combines swimming, endurance, and strategy. It can look intense and hardcore, but don't let that scare you away. If you can swim and have some stamina, you're already well on your way to being a great Water Polo player. And the best part? You don't even have to know how to play yet. The coaches at Water Polo Stavanger - Newbies will teach you everything you need to know.

    Currently, Water Polo Stavanger is working to create a women's team. But as always, they need more people. That's where you come in! If you're interested in trying out Water Polo, the Newbies group is the perfect place to start. The trainings are a lot of fun, and you'll be able to see improvement in your skills every time you get in the pool.

    Water Polo Stavanger offers two training days each week. Thursdays are focused on swimming technique, while Mondays are all about Water Polo playing. The coaches are experienced and passionate, and they'll make sure you get the most out of every session. And who knows? You may even discover a new passion that you never knew you had.

    If you're ready to dive in and try Water Polo, all you have to do is let the team know. You can reach out to them directly, or join the Waterpolo Stavanger - Newbies group to check it out for yourself. Don't be intimidated by the sport's reputation - give it a chance and see how much fun you can have in the water.
    
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "22 February, 2023",
    cover: "/assets/images/waterpolo.png",
  },

  {
    id: 28,
    title: "Galentines Party at Vesuvia! ",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: ` A Night of Love and Fun: Our Galentines Party at Vesuvia!

    At the end of February, around 30 girls gathered at Vesuvia restaurant in Stavanger for a night of love and fun in the spirit of sisterhood. The invitation read, "Hello our Gorgeous Ladies! It's about time that we meet again and start the year right with a night full of love and fun in the spirit of sisterhood! Would you join us and be our Galentines? 💘💌🥰"

    Vesuvia is an Italian pizzeria that serves both non-alcoholic and alcoholic drinks, making it the perfect venue for our party. One of the girls from Ladies of Stavanger is the owner of the restaurant, so it was even more special that they hosted us and took very good care of us throughout the evening.
    
    After enjoying delicious Italian pizza and dessert, the girls surprised Izabela with gifts and sang happy birthday to her. It was the perfect way to celebrate her birthday, which was just a couple of days away. The atmosphere was great, and everyone was having a good time.
    
    In addition to the food and drinks, we also got a glass of prosecco or cola, which added to the festive mood. It was a night to remember, and we were grateful to have each other as Galentines.
    
    As the night came to a close, we left feeling happy and grateful for the love and friendship we shared. We can't wait for the next Ladies of Stavanger event, and we hope to see even more of our Galentines there!
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "February 22, 2023",
    cover: "/assets/images/galentines.png",
  },

  {
    id: 29,
    title:
      "Ladies of Stavanger has officially become a non-profit organization! ",
    category: "development",
    subCategory: ["group", "comunity", "association"],
    description: ` Dear Ladies of Stavanger, We are thrilled to announce that starting from 22nd February 2023, Ladies of Stavanger has officially become a non-profit organization! This exciting news comes as we continue to expand our community, and we are grateful to have new admins to help us achieve our vision. 
    
    Our mission is to create a safe and welcoming space for Norwegian and foreign women in Stavanger and Rogaland. We believe that by gathering regularly, we can socialize, support each other, learn from each other, and help each other feel at home in Stavanger and Rogaland. 
    
    We achieve this through a variety of activities, including social evenings, sports events, and meetings with different themes such as integration, psychological help, and more. Becoming a non-profit organization is an important step for us as it will enable us to do even more for our community. 
    
    We are committed to using all resources available to us to provide our members with the best possible experience. We look forward to continuing to grow and strengthen our community. Thank you for your continued support, and we can't wait to see you at our next event! 
    
    XoXoXo, Your Admins
    
    `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "22 February, 2023",
    cover: "/assets/images/celebrate.png",
  },
  {
    id: 30,
    title: "Ladies of Stavanger Run for a Good Cause",
    category: "sport",
    subCategory: ["charity", "stay active", "let`s run together"],
    description: `  On a chilly Sunday afternoon, the Ladies of Stavanger gathered at Valuen for a charity run. The event was the 6th Løpet mot Diabetes charity run, held in support of the Great Orchestra of Christmas Charity.

  The Great Orchestra of Christmas Charity is a Polish charity organization that has been raising funds for hospitals and medical equipment for 31 years. This year, the Ladies of Stavanger participated in the event to collect money for hospital equipment for kids' departments in Poland.

  The run was simple and easy to participate in. There was no official registration, and participants only needed to throw any amount of NOK into the WOŚP can. They could run or walk any number of laps around the reservoir, with each lap being 1 km long. All participants received a commemorative medal and diploma from the run.

  The money raised from the event will be used to purchase insulin pumps for those who need them. Insulin pumps are devices that provide insulin to people with diabetes. They can help to control blood sugar levels and improve the quality of life for those who need them.

  The Ladies of Stavanger were not alone in their efforts. Many other people participated in the charity run, and lots of attractions awaited everyone who participated. In addition to the run itself, there was a bonfire, swimming for the brave, a mobile sauna, and delicious food.

  The Ladies of Stavanger were proud to participate in such a worthy cause. They were happy to be a part of an event that raised funds for hospital equipment for kids' departments in Poland. The event was a reminder that, even in these uncertain times, there are still opportunities to come together and make a difference in the lives of others.

  The Ladies of Stavanger would like to thank everyone who participated in the event and helped to make it a success. They look forward to participating in similar events in the future and continuing to make a difference in the lives of others.

  
`,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "22 January, 2023",
    cover: "/assets/images/WOSPrun.png",
  },
  {
    id: 31,
    title: "Winter swimming @ Vaulen",
    category: "sport",
    subCategory: ["winter swimming", "stay active", "let`s meet"],
    description: `  Come on in, the water's lovely! Join Our Ladies of Stavanger Winter Swimming Group. Winter swimming might sound like a crazy idea to some, but for our Ladies of Stavanger group, it's a regular and exhilarating activity that we look forward to almost every week. 
    
    There's nothing quite like the feeling of plunging into the icy waters of Vaulen beach, especially when you're surrounded by a supportive and fun-loving group of women. If you're curious about winter swimming and want to experience it for yourself, we invite you to join us. You don't need any special equipment or experience, just a sense of adventure and a willingness to try something new. 
    
    Our weekly winter swimming event takes place at Vaulen beach, which is easily accessible by car or public transport. If you're driving, there's free parking right by the beach. If you're taking the train, get off at Mariero station and walk 12 minutes to the beach. We usually meet early in the morning, when the air is crisp and the beach is quiet. After a quick change into our swimsuits, we make our way to the water's edge. 
    
    The temperature of the water is usually around 5°C, which might seem daunting at first, but trust us, it's worth it. The first few seconds of immersion can be a shock to the system, but after a few deep breaths, you'll start to feel invigorated and alive. 
    
    There's something about the cold water that makes you feel more alert, more present, and more connected to your body. After our swim, we warm up with hot drinks, snacks, and good company. There's nothing like a cup of hot tea or coffee to chase away the chill and bring people together. 
    
    We share stories, laughter, and a sense of camaraderie that comes from braving the cold together. One of the best things about winter swimming is that it's (probably?) good for you. 
    
    Studies have shown that cold water immersion can have a range of health benefits, from boosting your immune system to reducing inflammation and improving mood. But even if you're not convinced by the science, there's no denying the sheer joy and sense of accomplishment that comes from taking a dip in the winter sea. 
    
    It's a great way to shake off the cobwebs, challenge yourself, and connect with nature. So come on in, the water's lovely! And don't worry about shaving your legs - we're all in this together. Join us for a weekly dose of winter swimming, and discover a new side of yourself that you never knew existed.
    
    `,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "3 January , 2023",
    cover: "/assets/images/winterSwimming.jpg",
  },
  {
    id: 32,
    title: "JULEBORD!",
    category: "parties",
    subCategory: ["events", "comunity", "let`s meet"],
    description: ` The holiday season is a time for coming together and spreading cheer, and that's exactly what we did at our Christmas party this year! Almost 40 girls from our community gathered at Egon restaurant in the center of Stavanger to enjoy good food, fun games, and great company. 
    
    The restaurant had reserved a special section just for us, and we were treated to a variety of delicious meals, including traditional Norwegian Christmas dishes. As we ate, we took turns giving speeches and sharing a bit about ourselves, which helped us get to know each other better. Of course, no Christmas party would be complete without a visit from Santa Claus!
    Our main admin, Iza, donned a Santa suit and we had a lottery for Christmas gifts, which was a lot of fun. Our group's goal is to unite girls in our community, and this event was a perfect example of that. 
    
    Unfortunately, there was a small misunderstanding with the restaurant staff, and we were asked to move to the terrace after a couple of hours. But Sheryll, one of our admins, was a hero and negotiated free drinks for everyone from the restaurant's manager. We were grateful for this gesture and continued our celebrations on the terrace. 
    
    Later in the evening, we moved to a karaoke bar called Korvetten where we sang and danced the night away. It was wonderful to see so many familiar faces and to welcome new ones as well. This was the second year that we've organized a Christmas party, and it's a tradition that we plan to continue in the years to come. We want to thank all the lovely ladies who joined us and helped make the night a success. We hope that this event brought some joy and happiness to everyone who attended, and we look forward to seeing you all again soon! 
    
    Dear Sheryll! 
    
    We wanted to express our deepest gratitude for all the hard work and effort that you put into organizing our Christmas party. Your dedication and attention to detail truly made the event a success, and we couldn't have done it without you.
     From negotiating with the restaurant manager to arranging the gift lottery and making sure everyone was having a good time, you went above and beyond to make sure that everything ran smoothly. 
     Your positive attitude and willingness to help with anything and everything were truly appreciated by all of us. 
     Your contribution to this event was invaluable, and we are so grateful for your involvement. 
     You are a true asset to our admin group! Once again, thank you from the bottom of our hearts for making our Christmas party such a memorable and enjoyable experience for all of us. 
     
     With love and appreciation, Iza.
     
     `,

    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "3 December, 2022",
    cover: "/assets/images/christmasParty6.jpg",
  },
  {
    id: 33,
    title: "Rosa sløyfe-løpet",
    category: "sport",
    subCategory: ["charity", "stay active", "let`s run together"],
    description: `  The Ladies of Stavanger participated in the Pink Ribbon Race, an annual charity event that raises awareness and funds for breast cancer. The group joined the event not only to stay active but also to support a worthy cause. 
    
    The Pink Ribbon Race has been held for seven years in eleven different cities, with the 2020 event becoming Norway's largest virtual race with over 11,000 participants. The event aims to bring people together to contribute to the Pink Ribbon campaign, which helps those affected by breast cancer. 
    
    To participate, there were two runs available: a short trail of approximately 3 km, and a long trail of approximately 6 km, with classes for both men and women. The long trail had a timing system and an age limit of turning 11 years old. 
    The registration fee for the virtual ticket was NOK 300, while the physical event cost NOK 400. Half of the registration fee went directly to the Pink Ribbon campaign, while the other half went toward event implementation. 
    Before the run, the participants warmed up together as a group. The Ladies of Stavanger, along with other participants, took part in the event to show their support and solidarity for those affected by breast cancer. 
    
    Through their participation in the Pink Ribbon Race, the Ladies of Stavanger joined the thousands of other participants in raising awareness and funds for breast cancer. The event provided an opportunity to not only stay active but also to make a positive impact on the community. 
    
    Here is the link to this year's event, join us, let's stay active: https://www.rosasloyfelopet.no/stavanger/


      `,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "6 October, 2022",
    cover: "/assets/images/brestCancerRun4.jpg",
  },
  {
    id: 34,
    title: "New admins recruitment",
    category: "development",
    subCategory: ["group", "comunity", "association"],
    description: `  I am delighted to announce that we have welcomed a new team of admins to our Ladies of Stavanger group. 
    As our group continues to grow rapidly, we recognize the need for additional support to help us develop and implement new ideas. 
    
    The new admins have already made a significant impact, contributing their time and energy to organize exciting events and activities for our members. It is not easy to manage such a group alone, and their willingness to lend a helping hand is greatly appreciated. We would like to take this opportunity to officially introduce our new admin team to you all. 
    
    We value your feedback and suggestions as we strive to create a space that caters to your needs and interests. Please feel free to share your thoughts and opinions with us. Is there something you would like to see more of in the group? Do you have any ideas for new activities or events? We are all ears! Thank you for your continued support, and we look forward to working together with our new admins to make this group even more amazing.
    
    `,
    authorName: "Izabela Buczynska",
    authorAvatar: "/assets/images/author.jpg",
    createdAt: "22 September, 2022",
    cover: "/assets/images/celebrate.png",
  },
];
