import React from "react";
import "./styles.css";

const Membership = () => (
  <div className="container">
    <header className="about-content">
      <h2>How to</h2>
      <h1>
        <span></span> Join and support<span> Ladies of Stavanger</span>
      </h1>
      <p>
        We're excited to welcome you to our community! Connect with us on our lively{" "}
        <a href="https://www.facebook.com/groups/ladiesofstavanger"> Facebook page </a> to meet incredible people and experience our vibrant atmosphere. To fully support our organization, we invite you to become a member.
      </p>
      <p>
        Membership, at just 105 NOK annually <b>(Vipps:809926)</b>, offers benefits and enriches your experience with us. The process is simple and hassle-free.
      </p>
      <p>
        Fill out the{" "}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdx9PeS4lXWhTXxS3eMoelAA8TV5iazHCxtG9fq9DInC5dptw/viewform?usp=pp_url">
          membership form
        </a>{" "}
        with your details.
      </p>
      <section>
        <h3>Member Benefits:</h3>
        <div><b>•</b> Networking with like-minded individuals</div>
        <div><b>•</b> Access to social and career development programs</div>
        <div><b>•</b> Members-only events</div>
        <div><b>•</b> Advocacy on important issues</div>
        <div><b>•</b> Volunteering opportunities</div>
        <div><b>•</b> Discounts and special offers</div>
        <div><b>•</b> Member Discounts from local businesses and entrepreneurs</div>
        <div><b>•</b> Recognition for contributions and achievements</div>
        <div><b>•</b> Platform for creating positive change</div>
      </section>
      <section>
        <h3> Discounts from our Supporters:</h3>
        <div><b><a href="https://www.facebook.com/profile.php?id=61556551544674">Snitt Frisørstue</a></b>: 10% off single service, 15% off multiple services</div>
        <div><b><a href="https://www.facebook.com/heatfitnessclub">Heat Fitness</a></b>: Different discounts every month</div>
        <div><b><a href="https://www.facebook.com/profile.php?id=100076127831690">Time for You - Beauty Center Daria</a></b>: 15% off chosen treatments</div>
        <div><b><a href="https://www.facebook.com/trianglestudiostavanger">Triangle Studio</a></b>: 10% off 10 dance class clip card, 10% off 5 singing lessons</div>
        <div><b><a href="https://www.facebook.com/tinyproductions.no">Tiny Productions</a></b>: 10% off photoshoots</div>
        <div><b><a href="https://www.facebook.com/profile.php?id=100058136515539">Beauty House</a></b>: 10% off all services</div>
        <div><b><a href="https://www.facebook.com/Robertobeautystudio">Robertas Ket</a></b>: 10% off services</div>
        <div><b><a href="https://www.facebook.com/tikistavanger">Aloha Stavanger</a></b>: 15% off</div>
        <div><b><a href="https://www.facebook.com/bardellostavanger">Bardello</a></b>: 15% off</div>
        <div><b><a href="https://www.facebook.com/DermaMedispa.no">Derma MediSPA</a></b>: 15% off all treatments (except those involving prescription drugs), 10% off SkinCeuticals products</div>
        <div><b><a href="https://www.facebook.com/mattrening">Mat og Trening</a></b>:</div>
        <div><b>• Stavanger:</b></div>
        <div>
          <div>Kangoo Jumps 10 sessions: 1000 NOK (regular price 1300 NOK)</div>
          <div>Tanita body analysis test + nutrition consultation + individual meal plan (45 min): 500 NOK (regular price 690 NOK)</div>
          <div>Personal Training: 10% off any chosen monthly membership</div>
          <div>Creating your own group training: 3-5 people - 700 NOK, 6-10 people - 1100 NOK, 11-15 people - 1400 NOK per session</div>
        </div>
        <div><b>• Sandnes:</b></div>
        <div>Monthly membership: 790 NOK (regular price 1199 NOK)</div>
      </section>
      
      <section>
      <h3>Remember</h3>
          By becoming a member, you help sustain our community and enable us to secure additional funding for more events and opportunities. Join us and be part of a passionate, supportive, and inclusive community dedicated to making a difference in Stavanger.
        
      <div className="col-md-4">
      <img className="Los-img" src="../assets/images/pantera.png" alt="vipps" />
    </div>
    <br />
    </section>
    </header>
  </div>
);

export default Membership;
