import React from "react";
import "./styles.css";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "LADIES Of STAVANGER"}
                  <span></span>
                </h1>
                <div>
                  {" "}
                  <img src="../../assets/images/motto.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
