import React, { useState } from "react";
import Header from "../../components/Home/Header";
import ShortAbout from "../../components/Home/ShortAbout";
import Upcoming from "../../components/Home/Upcoming";

const Home = () => {
  return (
    <div>
      {/* Page Header */}
      <Header />
      {/* ShortAbout */}
      <ShortAbout />
      {/* Upcoming */}
      <Upcoming />
    </div>
  );
};

export default Home;
