import React from "react";
import "./styles.css";

const SectionAbout = () => (
  <div className="container">
    <header className="about-content">
    <h1>
  <span></span>Hi my Lovely Ladies<span>!</span>
</h1>   
<h2>Little bit more about us</h2>
<div className="col-md-4">
  <img className="Los-img" src="../assets/images/iziaLos.png" alt="event1" />
</div>
<br />

      <p>
        {" "}
        <b>Welcome to Ladies of Stavanger</b>, the go-to spot for women looking to make new friends and enjoy fun events in Stavanger. We’re all about connecting with like-minded gals, having a good time, and building lasting friendships in a friendly, supportive community.  <br /> <br />
      </p>
      <p>
        {" "}
        <b>Mission:</b> Our mission is to create a supportive and inclusive
        community for diverse women in Stavanger. We aim to provide a platform
        for socializing, exchanging ideas, and fostering friendships in a safe
        and welcoming environment. <br /> <br />
      </p>

      <p>
        {" "}
        <b>Vision:</b> As a non-profit organisation, we’re excited to foster a supportive community where women can connect and grow. Our events and activities are designed to bring people together, celebrate diverse experiences, and build a more inclusive society. We look forward to a future where everyone in Stavanger can feel connected and engaged. Stay tuned and join us on this journey!
        <br /> <br />
      </p>
    </header>
  </div>
);

export default SectionAbout;
